import { useContext, createContext } from 'react'

const GlobalContext = createContext({})

const useGlobalContext = () => {
  const globalContext = useContext(GlobalContext)

  return globalContext
}

const GlobalProvider = ({ children, value }) => (
  <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
)

export { GlobalProvider, useGlobalContext }
