import { useEffect, useState } from 'react'
import { Hub, Auth } from 'aws-amplify'

const checkIsAuthenticated = (user) => {
  if (
    !user ||
    !user.getSignInUserSession() ||
    !user.getSignInUserSession()?.isValid() // isValid() also verified the Token Signature
  ) {
    return false
  }

  return true
}

const useAuthentication = () => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    Hub.listen('auth', handleAuthStateChange)

    const captured = /autologin=([^&]+)/.exec(window.location.href)
    const autologin = captured ? captured[1] : false

    const initialize = async () => {
      const authState = await handleAuthStateChange({ event: 'init' })

      if (autologin && !authState.isAuthenticated) {
        Auth.federatedSignIn()
      }
    }

    initialize()
  }, [])

  const handleAuthStateChange = async (payload) => {
    let authState = {
      isAuthenticated: false,
      user: null,
    }

    switch (payload.event) {
      case 'configured':
      case 'signIn':
      case 'signIn_failure':
      case 'signOut':
      case 'init':
        setLoading(true)

        try {
          const user = await Auth.currentAuthenticatedUser()
          const authenticated = checkIsAuthenticated(user)

          authState.user = user
          authState.isAuthenticated = authenticated

          setUser(user)
          setAuthenticated(authenticated)
        } catch (err) {
          // There is no session
        }

        setLoading(false)
        break
      default:
        break
    }

    return authState
  }

  const signOut = () => Auth.signOut()
  const signIn = () => Auth.federatedSignIn()

  return { user, loading, isAuthenticated, signOut, signIn }
}

export default useAuthentication
