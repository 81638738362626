import { Auth } from 'aws-amplify'

const RequireAuth = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    Auth.federatedSignIn()
    
    return null
  }

  return children
}

export default RequireAuth
